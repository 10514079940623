// import { StaticQuery } from 'gatsby';
// /* eslint-disable react/jsx-props-no-spreading */
// import { ThemeProvider } from 'styled-components';
// import GlobalProvider from '../components/GlobalContext/GlobalProvider';
import React from 'react';
import { getUrlParameter, getPhonetTehFinancial } from '../helpers/helpers';
import SEO from '../components/seo';
import theme from '../themes/tehfinancialTheme';
import tehfinancialData from '../data/tehfinancial';
import Layout from '../components/layout';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/FooterSection';
import ATEForm from '../components/FormSection/ATEForm';
import Disclaimer from '../components/Disclaimer/Disclaimer';
import TwoColumns from '../components/TwoColumns/TwoColumns';
import HeroSection from '../components/HeroSection/HeroSection';
import InfoSection from '../components/InfoSection/InfoSection';
import HelpSection from '../components/HelpSection/HelpSection';
import fbImg from '../images/tehfinancial-socialshare.png';

const {
  logos,
  credibilityLogos,
  heroSection,
  infoSection,
  helpSection,
  form,
  disclaimer,
  footer,
} = tehfinancialData;

let { headerPhone } = tehfinancialData;

const pid = getUrlParameter('pid');

const realPhone = getPhonetTehFinancial(pid);

headerPhone = Object.assign(headerPhone, realPhone);

const seo = {
  title: 'Free Service Helps You Connect with Experts and Find Resources.',
  description:
    'Teh Financial Coaching has partnered with Debt.com to provide a free service to answer any questions you have about your debt, budget, mortgage, credit and taxes, and find resources to help you get through this crisis.',
  creator: '@debtcom',
  site_name: 'Debt.com',
  url: 'https://helpline.debt.com/tehfinancial',
  fbImg,
  twImg: fbImg,
};

const tehfinancialPage = () => (
  <Layout
    logos={logos}
    credibilityLogos={credibilityLogos}
    headerPhone={headerPhone}
    heroSection={heroSection}
    infoSection={infoSection}
    helpSection={helpSection}
    disclaimer={disclaimer}
    footer={footer}
    theme={theme}
    seo={seo}
  >
    <SEO
      lang="en"
      twImg={seo.twImg}
      fbImg={seo.fbImg}
      title={seo.title}
      creator={seo.creator}
      description={seo.description}
      url={seo.url}
    />
    <Header showCredibilityLogos />
    <HeroSection />
    <InfoSection />
    <TwoColumns>
      <ATEForm data={form} lang="en" phoneData={realPhone} />
      <HelpSection />
    </TwoColumns>
    <Disclaimer />
    <Footer />
  </Layout>
);

export default tehfinancialPage;
